$fontstack: 'Poppins', sans-serif;
$fontbody: 'Open Sans', sans-serif;
$colordefault: #4E4E50;
$colorblue: #1A1A1D;
$colororange: #950740;

@mixin transition($second) {
  -webkit-transition: all $second ease-in-out;
    -moz-transition: all $second ease-in-out;
        transition: all $second ease-in-out;
}